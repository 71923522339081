<template>
  <v-card flat tile>
    <v-toolbar dense :color="edit ? 'color1 color1Text--text' : 'grey lighten-3'" flat>
      <v-toolbar-title v-if="flight.pools.length">
        {{poolDesc | formatArray}} ({{flight.poolTeams.length}} teams total)
      </v-toolbar-title>
      <v-toolbar-title v-else>
        {{flight.poolCount}} Pools ({{teamCount}} teams total)
      </v-toolbar-title>
    </v-toolbar>
    <v-container fluid class="py-0">
      <v-row dense>
        <v-col
          cols="12"
          v-if="flight.powerPools && flight.powerPools > 0"
          class="caption grey--text"
        >
          Power Pools: {{flight.powerPools}}
        </v-col>
        <v-col class="text-right py-0" cols="12">
          <v-btn
            v-if="!forceAdvanced"
            color="color3"
            text
            class="xsmall"
            @click.stop="advanced = !advanced"
            :disabled="!edit || forceAdvanced"
          >{{advanced ? 'simple' : 'advanced'}}</v-btn>
        </v-col>
        <v-col cols="12" class="d-flex py-0">
          <v-checkbox
            label="KOB/QOB"
            v-model="flight.props"
            value="kob"
            color="color3"
            class="mt-0 shrink mr-3"
            hide-details
            disabled
          ></v-checkbox>
          <v-checkbox
            label="Duals"
            v-model="flight.isDuals"
            color="color3"
            class="mt-0 shrink"
            hide-details
            disabled
          ></v-checkbox>
        </v-col>
        <v-expand-transition>
          <v-col cols="12" v-if="(advanced || forceAdvanced)">
            <tiebreak-select
              v-model="tiebreaks"
              :disabled="!edit"
              :fivb="flight.fivb"
            >
            </tiebreak-select>
          </v-col>
        </v-expand-transition>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="title py-0">
          Match Settings
        </v-col>
        <v-divider></v-divider>
        <v-col cols="12" md="6" v-for="(setting) in flight.settings.filter(f => f.poolsOf > 0)" :key="setting.poolsOf" class="py-0">
          <v-card class="elevation-1">
            <v-toolbar dense :color="edit ? 'color2 color2Text--text' : 'grey lighten-3'" flat>
              <v-toolbar-title>Pools of {{setting.poolsOf}}</v-toolbar-title>
            </v-toolbar>
            <loading-bar :value="loading"></loading-bar>
            <v-card-text v-if="getTemplates(setting.poolsOf).length" class="pb-0 px-3">
              <v-select
                :items="getTemplates(setting.poolsOf)"
                item-text="name"
                item-value="id"
                v-model="setting.organizationTemplateId"
                label="Custom Template"
                :disabled="!edit || (flight.fivb && setting.poolsOf === 4)"
                color="color3"
                item-color="color3"
                dense
              ></v-select>
            </v-card-text>
            <match-settings
              :setting="setting"
              :edit="edit"
            ></match-settings>
            <v-card-text class="pt-0" v-if="setting.poolsOf === 4">
              <v-checkbox
                label="Modified Olympic Pools"
                v-model="flight.fivb"
                v-if="setting.poolsOf === 4"
                :disabled="!edit"
                color="color3" hide-details class="mt-0"
              ></v-checkbox>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import MatchSettings from './MatchSettings.vue'
import TeamSelectionOptions from '@/classes/TeamSelectionOptions'
import SeedingStyles from '@/classes/SeedingStyles'
import uniq from 'lodash.uniq'
import { mapGetters } from 'vuex'
import Tiebreaks from '@/classes/Tiebreaks'
import TiebreakSelect from '@/components/Standings/TiebreakSelect'

export default {
  props: ['flight', 'title', 'edit', 'deletable', 'teamCount'],
  data () {
    return {
      fab: false,
      advanced: false,
      oDto: null,
      loading: false,
      templates: []
    }
  },
  computed: {
    ...mapGetters(['host']),
    tiebreaks: {
      get () {
        return this.flight.tiebreaks ? this.flight.tiebreaks.split(',') : Tiebreaks.filter(f => f.default).map(m => m.name)
      },
      set (val) {
        this.flight.tiebreaks = val.join(',')
      }
    },
    tiebreakOptions () {
      return Tiebreaks
    },
    forceAdvanced () {
      return this.flight.tiebreaks && this.flight.tiebreaks !== Tiebreaks.map(m => m.name).join(',')
    },
    activeFab () {
      return this.edit ? { color: 'red white--text', icon: 'fas fa-times' } : { color: 'color3Text color3--text', icon: 'fas fa-pencil-alt' }
    },
    dirty () {
      return JSON.stringify(this.flight.dto) !== this.oDto
    },
    poolDesc () {
      const pools = this.flight.pools.map(p => p.teams.length)
      return uniq(pools).map(n => {
        const count = pools.filter(f => f === n).length
        const p = count === 1 ? `${count} pool` : `${count} pools`
        return `${p} of ${n} teams`
      })
    },
    poolPullOptions () {
      return TeamSelectionOptions
    },
    resultOptions () {
      return SeedingStyles
    },
    dto () {
      return this.flight.dto
    }
  },
  methods: {
    getTemplates (n) {
      const a = this.templates.filter(f => f.type === 'Pool' && f.template.poolsOf === n)
      if (a.length) a.unshift({ name: 'None', id: null })
      return a
    },
    clone () {
      this.oDto = JSON.stringify(this.flight.dto)
    },
    loadTemplates () {
      this.loading = true
      this.$VBL.organization.templates.get(this.host, 'pool')
        .then(r => {
          this.templates = r.data
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
        })
    }
  },
  watch: {
    'flight.selectedOption': function (n, o) {
      if (n && n !== o) {
        // this.flight.onOptionSelected()
      }
    },
    'flight.fivb': function (v) {
      if (v) {
        this.flight.settings.filter(f => f.poolsOf === 4).forEach(s => { s.organizationTemplateId = null })
      }
    },
    edit: function (val) {
      if (val) {
        this.clone()
      } else {
        this.advanced = false
      }
    }
  },
  mounted () {
    this.loadTemplates()
  },
  components: {
    MatchSettings,
    TiebreakSelect
  }
}
</script>

<style>

</style>
