<template>
  <v-container class="pt-0">
    <v-row dense>
      <v-expand-transition>
        <v-col cols="12" class="pa-0 text-end" style="min-height: 24px">
          <v-btn v-if="edit"
            color="color3"
            text
            x-small
            @click.stop="advanced = !advanced"
          >
            {{!advanced ? 'Advanced' : 'simple'}}
          </v-btn>
        </v-col>
      </v-expand-transition>
      <v-expand-transition>
        <v-col cols="12" v-if="([1,4,5].includes(setting.matchTypeId) && advanced) || this.setting.isTimed" class="justify-end d-flex">
          <v-checkbox
            label="Timed Game(s)"
            v-model="setting.isTimed"
            color="success"
            class="mt-0 shrink"
            :disabled="!edit"
          ></v-checkbox>
        </v-col>
      </v-expand-transition>
      <v-col cols="12" :sm="([1,4,5].includes(setting.matchTypeId) || advanced || setting.isAdvanced) && !this.setting.isTimed ? 6 : 12">
        <v-select
          :label="label || `Match Type`"
          :items="playOptions"
          item-text="text"
          item-value="id"
          return-object
          v-model="setting._matchType"
          :hint="!setting.isAdvanced && setting._matchType ? setting._matchType.hint : ''"
          persistent-hint
          :disabled="!edit"
          color="color3"
          item-color="color3" dense
        ></v-select>
      </v-col>
      <template v-if="([1,4,5].includes(setting.matchTypeId) || advanced || setting.isAdvanced) && !this.setting.isTimed">
        <template v-for="(game, i) in setting.gameSettings">
          <v-col cols="6" sm="3" :offset-sm="i> 0 ? 6 : null" :key="'to_' + i" class="py-0">
            <v-text-field
              label="Played to"
              type="number"
              v-model="game.to"
              :disabled="!edit"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="3" :key="'cap_' + i" class="py-0">
            <v-text-field
              label="Cap"
              type="number"
              v-model="game.cap"
              :disabled="!edit"
              dense
            ></v-text-field>
          </v-col>
        </template>
      </template>
      <v-col cols="12" v-if="!notime" class="py-0">
        <wave-settings :wave="setting" :edit="edit"></wave-settings>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MatchOptions from '@/classes/MatchOptions'
import WaveSettings from './WaveSettings.vue'

export default {
  props: ['setting', 'edit', 'label', 'notime', 'bracket', 'round'],
  data () {
    return {
      advanced: false,
      timed: false
    }
  },
  computed: {
    playOptions () {
      return MatchOptions
    }
  },
  watch: {
    edit: function (val) {
      this.timed = this.setting.isTimed
    }
  },
  components: {
    'wave-settings': WaveSettings
  }
}
</script>

<style>

</style>
