<template>
  <v-container fluid class="pa-0">
    <v-row dense align="center" v-if="false">
      <v-col cols="12">
        <v-checkbox
          label="Assign Match Times"
          v-model="wave.assignTimes"
          hide-details
          class="mt-0"
          :disabled="!edit"
          dense
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row dense v-if="wave.assignTimes">
      <v-col cols="12" sm="6" v-if="false">
        <date-time-picker
          :datetime.sync="wave.startTime"
          type="time"
          label="Pool Start"
          :disabled="!edit"
          dense
        ></date-time-picker>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          type="number"
          v-model="wave.minutesPerMatch"
          v-if="wave.assignTimes"
          label="Minutes Per Match"
          hint="We suggest including warm up time"
          persistent-hint
          :disabled="!edit"
          dense
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DateTimePicker from '@/components/Utils/DateTimePicker.vue'

export default {
  props: ['wave', 'name', 'edit'],
  components: {
    'date-time-picker': DateTimePicker
  }
}
</script>

<style>

</style>
