<template>
  <v-select
    :items="tiebreakOptions"
    v-model="tiebreaks"
    item-value="name"
    multiple
    chips
    deletable-chips
    label="Tie Breaks"
    color="color3"
    item-color="color3"
    :disabled="disabled"
    :menu-props="{
      closeOnClick: true,
      closeOnContentClick: true
    }"
    :hint="fivb ? '*Tie breaks do not apply to Modified Olympic Pools' : ''"
    :persistent-hint="fivb"
  >
    <template slot="selection" slot-scope="data">
      <v-chip
        :key="data.item.name"
        :color="disabled ? 'grey lighten-3 grey--text' : 'color3 color3Text--text'"
      >
        <v-icon
          v-if="data.index > 0"
          left
          class="clickable"
          @click.stop="move(data.index, -1)"
        >fas fa-caret-left</v-icon>
        {{data.item.text}}
        <v-icon
          small
          class="ml-2 clickable"
          @click.stop="remove(data.index)"
          v-if="!data.item.status"
        >fas fa-times</v-icon>
        <v-icon
          v-if="data.index < (tiebreaks.length - 1)"
          right
          class="clickable"
          @click.stop="move(data.index, 1)"
        >fas fa-caret-right</v-icon>
      </v-chip>
    </template>
  </v-select>
</template>

<script>
import Tiebreaks from '@/classes/Tiebreaks'

export default {
  props: ['value', 'disabled', 'fivb'],
  data () {
    return {
      tiebreaks: this.value
    }
  },
  computed: {
    tiebreakOptions () {
      return Tiebreaks
    }
  },
  methods: {
    handleInput (e) {
      this.$emit('input', this.tiebreaks)
    },
    remove (i) {
      this.tiebreaks.splice(i, 1)
    },
    move (i, n) {
      this.tiebreaks.splice((i + n), 0, ...this.tiebreaks.splice(i, 1))
    }
  },
  watch: {
    tiebreaks: 'handleInput'
  }
}
</script>
